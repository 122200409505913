.AudioPlayer {
  width: 100%;
}

.Table {
  display: table;
  vertical-align: middle;
  width: 100%;
}

.TableCell {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
}