.YoutubeUrlSelector{
}
.TrackInfoZone {
  padding-inline-start: 40px;
}

.TrackInfoEntry {
  list-style: decimal-leading-zero;
}

.ListZone {
  margin-block-start: 1em;
  margin-block-end: 1em;
  max-height: 300px;
  overflow: auto;
}

.ResourceZone {
}

.Separator {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.Uploader {
  font-weight: bold;
}

.Duration, .Id {
  margin-left: 5px;
  font-style: italic;
  color: grey;
}