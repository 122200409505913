.FileTreeView {
  display: flex;
  height: 100%;

  > div {
    flex: 1 1;
  }
}

.TableCell {
  font-size: 11px;
}

.HeaderCell {
}

.SizeStrong {
  span:first-child{
    margin-right: 3px;
  }

  span:nth-child(2) {
    color: #a4c981;
  }
}

.DurationStrong{
  span {
    color: #a4c981;
  }
}