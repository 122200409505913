.PlayingControls {
  padding: 4px 10px 0 10px;

  > div {
    display: table;

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    > div:not(.Button) {
      padding: 0 2px;
    }
  }

  img {
    width: 28px;
    filter: hue-rotate(-30deg) brightness(1);
    transition: filter 0.1s ease-in-out;
  }
}

.PlaylistControls {
  padding: 0 10px;

  > div {
    display: table;

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    > div:not(.Button) {
      padding: 0 5px;
    }
  }
}

.PausedButton {
  filter: brightness(2);
  animation: PauseButton 1.5s infinite;
}

.Button {
  filter: brightness(1);
  transition: filter 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    transition: filter 0.1s ease-in-out;
  }
}

.Volume {
  padding: 0 10px;

  > div {
    display: table;
  }

  > div > div {
    display: table-cell;
    vertical-align: middle;
    padding: 0 3px;
  }

  input[type="range"] {
    position: relative;
    top: -5px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: rgba(0, 0, 0, 0.5);
    height: 6px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -5px;
    background-color: grey;
    border-radius: 8px;
    height: 16px;
    width: 16px;

    &:hover {
      background-color: #758f24;
    }
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid #758f24;
    outline: 2px solid #758f24;
    outline-offset: 2px;
  }
}

.ProgressBar {
  width: 100%;
  padding: 0 10px;

  > div {
    width: 100%;
  }
}

.Elapsed {
  padding: 0 5px;
  font-size: 13px;
  min-width: 80px;
  text-align: right;
}

.Duration {
  padding: 0 5px;
  font-size: 13px;
  min-width: 70px;
  text-align: left;
}


.Replay {
  &:hover {
    animation: fullRotate 1.5s infinite;
  }
}


@keyframes fullRotate {
  from {
    transform: rotate(0deg);;
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes PauseButton {
  from {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}