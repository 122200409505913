.LibraryPlaylistInfoZone {
  font-size: 12px;

  overflow: auto;
  height: 100%;

  > div {
    height: 100%;
    width: 100%;
  }

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;
  }

  td {
    padding: 2px 5px 2px 5px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  td:before {
    content: ';';
    visibility: hidden;
  }
}


.InfoTitle {

  & > span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.InfoIndex {
  width: 10px;
  text-align: left;
  font-size: smaller;
  color: #8e8e8e;
}


.InfoDuration {
  width: 50px;
  text-align: right;
  font-size: smaller;
}

.Playing {
  font-weight: bold;
}