
.Links {
  flex: 0 1;

  > div {
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 14px;

    & > span {
      margin: 5px 10px;
      padding: 10px 20px 15px 20px;
      border: 5px solid transparent;
      display: inline;
      vertical-align: middle;
      opacity: 0.5;
      &:hover{
        opacity: 1;
        cursor: pointer;
      }

      img {
        vertical-align: middle;
        width: 32px;
        margin-right: 10px;
        filter: invert(1);
      }

      & > span {
        vertical-align: middle;
      }

      &:first-child {
        border: 5px solid #758f24;
      }

      &:nth-child(2) {
        border: 5px solid orange;
      }

      &:nth-child(3) {
        border: 5px solid red;
      }
    }
  }

}

.SelectedLink {
  opacity: 1 !important;
}