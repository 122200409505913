.ResourceRatingTool {

}

.Star {
  display: inline-block;
  vertical-align: middle;
  filter: brightness(1);
  margin-left: 1px;
  transition: all 100ms ease;
  opacity: 0.4;
}





//  modif + scroll fait nimlp









.Hovered {
  filter:  brightness(2) !important;
  transition: all 150ms ease;
  opacity: 1;
  transform: scale(1.1);
}

.FullStar {
  opacity: 0.7;
}

.Star:last-child {
  margin-right: 1px;
}