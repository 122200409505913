
.Menus {
  flex: 1 1 auto;


  > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  li {
    animation-name: example;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
}


@keyframes example {
  0% {
    border: rgba(0, 0, 0, 0.5) 7px solid;
  }
  30% {
    border: rgba(0, 0, 0, 0.5) 7px solid;
  }
  33% {
    border: rgba(0, 0, 0, 0.1) 7px solid;
  }
  36% {
    border: rgba(0, 0, 0, 0.5) 7px solid;
  }
  100% {
    border: rgba(0, 0, 0, 0.5) 7px solid;
  }
}

.Menus li a {
  display: flex;
  flex-direction: column;
  transition: 0.1s ease all;
  text-decoration: none;
  white-space: nowrap;
  color: #E8E6E3;
}

.Menus li:hover a {
  transition: 0.1s ease all;
  text-decoration: none;
}

.Menus li a span {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.Menus li a img {
  vertical-align: middle;
}

.Menus li:first-child {
  background: rgb(36, 111, 143);
}

.Menus li:nth-child(2) {
  background: rgb(190, 47, 43);
  animation-delay: 150ms;
}

.Menus li:nth-child(3) {
  background: rgb(117, 143, 36);
  animation-delay: 300ms;
}

.Menus li:nth-child(4) {
  background: rgb(183, 100, 35);
  animation-delay: 450ms;
}

.Menus li:nth-child(5) {
  background: rgb(196, 165, 45);
  animation-delay: 600ms;
}

.Menus li:nth-child(6) {
  background: #d6005c;
  animation-delay: 750ms;
}

.CurrentMenu {
  border-color: rgba(255, 255, 255, 0.5) !important;;
}

.HeaderContent {
  padding: 0 10px 40px 0;
  font-size: 30px;

  > div:last-child {
    font-weight: bold;
    color: #71a114;
  }
}