@import "../../../font";


.Content {
    transition: 0.2s ease-in all;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    display: flex;

    iframe{
        width: inherit;
        height: 100%;
    }
    > div {
        flex: 1 1;
        width: 100%;
        display: flex;
        height: 100%;
    }
}

/*body:not(:hover) .Content {
    transition: 0.5s ease-in all;
    background: #323232;
    color: #000000;
}*/

.ContentWrapper {
    width: 100%;
}

.ContentWrapper .textual {
    display: block;
    width: 90%;
    padding: 6px 10px;
}

.ContentWrapper label {
    display: block;
}

.ContentWrapper button {
    margin: 10px 10px;
    padding: 8px 16px;
    background: #8e8e8e;
}

.ContentWrapper button:hover {
    cursor: pointer;
}