
.Playlist {
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.ResourceInfo {
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.15);
}

.PlaylistElements {
  min-height: 40%;
}