.DirectoryResourceEntry {
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;

  &:hover {
    transition: all 0.1s ease;
    background: rgba(0, 0, 0, 0);
  }
}

.RealDownloadButton{
  vertical-align: middle;
  text-align: center;
  padding: 1px;
  color: white;
  background-color: #71a114;
  transition: filter 150ms ease-in;
  border-radius: 2px;
  filter: brightness(0.8);

  > span {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    top: -2px;
    position: relative;
  }

  &:hover{
    transition: filter 150ms ease-out;
    filter: brightness(1.1);
    cursor: pointer;
  }
}