.HeaderUserInfo {
  & > span:nth-child(1) {
    margin-right: 5px;
  }
  & > span:nth-child(2) {
    color: #71a114;
  }
  & > span:nth-child(3) {
    margin-right: 5px;
    margin-left: 5px;
    > span {
      color: #71a114;
    }
  }
}