
.ResourceEntry {

  width: 100%;

  transition: all 0.1s ease;
  vertical-align: middle;
  border-bottom: 1px solid #8e8e8e;
  position: relative;

  td {
    vertical-align: middle;
    position: relative;
  }
  td:before {
    content: ' ';
    visibility: hidden;
  }

  td > span {
    position: absolute;
    left: 0;
    right: 0;
    padding: 2px 5px;
    vertical-align: middle;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover .ActionIcons img, &.Selected  .ActionIcons img{
    opacity: 1;
  }

  &.Selected > td {
    background: rgba(153, 205, 50, 0.3);
  }
}


.Checkbox {
  width: 25px;
}

.Icon {
  width: 25px;

  img {
    transition: all 0.2s ease;
    height: 22px;
    width: 22px;
    vertical-align: middle;
  }
}

.Title {
  font-style: italic;
  font-size: 12px;

  > span {
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.Size {
  width: 80px;
  font-size: 12px;
  text-align: right;
}
.Count {
  width: 60px;
  font-size: 12px;
  text-align: right;
}

.ActionIcons {
  width: 150px;

  text-align: right;

  img {
    transition: all 0.2s ease;
    filter: brightness(0.6);
    height: 20px;
    width: 20px;
    opacity: 0;
    vertical-align: middle;
  }

  > span > span:not(:first-child){
    margin-left: 5px;
  }
}
.TagIcons {
  width: 100px;
}

.Clickable {


  .Unclickable {
    filter: grayscale(1);
  }
  & span:not(.Unclickable):hover img {
    transition: all 0.2s ease;
    filter: brightness(1);
    cursor: pointer;
  }
}

.DownloadButton{

}

.DeleteButton img {
}
