.FormatCodeSelector {
}

.YoutubeDLFormatEntry {

  td {
    padding-right: 5px;
    font-size: 12px;
    vertical-align: middle;
  }

  img {
    width: 18px;
    filter: invert(1);
    vertical-align: middle;
  }

}