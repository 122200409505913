.LibraryResourcePropertiesForm {

}

.Form {

  > div {

  }
}

.Left, .Right{
  width: 50%  ;
  > div {
    padding: 0 10px;
  }
}

.Buttons {
  > div {
    margin-top: 20px !important;
  }

  > div > div {
    margin: 0;
  }

  > div > div > div {
    display: inline-block;
    border-bottom: 0 solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-radius: 0;
    padding: 5px 20px;
    transition: all 0.1s ease;
    filter: brightness(1);

    &:hover {
      border-bottom: 0 solid transparent;
      border-top: 2px solid rgba(255, 255, 255, 0.1) !important;
    }
  }

  > div > div:nth-child(2) > div:hover {
    border-left: 2px solid rgba(255, 255, 255, 0.1);
  }

  > div > div:nth-child(3) > div:hover {
    border-right: 2px solid rgba(255, 255, 255, 0.1);
  }
}

.RatingItem{

  > div{
    position: relative;
    right: 0;
    width: 120px;
  }
}