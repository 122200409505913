.LibraryScanStatusFooter {
  border-top: 1px solid rgba(0,0,0,0.3);
}

.ProgressBarZone {

  > div {
    padding:10px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.IconZone{

  > div {
    padding-left: 15px;
    padding-right: 10px;
    position: relative;
    border-right: 2px solid rgba(255,255,255,0.5);
    top: 50%;
    transform: translateY(-50%);
  }
  img{
    width: 32px;
  }
}

.InfoZone{
  > div {
    padding: 5px 10px 5px 10px;
    width: 400px;
  }
}