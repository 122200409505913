.LibraryAltToolbar {
  background: rgba(0, 0, 0, 0.15);

  & > div {
    padding: 2px 3px 3px 3px;

  }
}

.TypeButton {
  display: inline-block;
  vertical-align: middle;
  padding: 3px 6px;
  margin-right: 3px;
  margin-left: 2px;
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border-color: rgba(255, 255, 255, 0.3);
  }

  & > span:first-child {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & > img {
    margin-right: 5px;
    display: inline-block;
    vertical-align: bottom;
    filter: invert(1);
    width: 16px;
  }
}

.TypeButton:nth-child(1).SelectedTypeButton {
  background: rgba(36, 111, 143, 0.3);
}

.TypeButton:nth-child(2).SelectedTypeButton {
  background: rgba(214, 45, 40, 0.3)
}

.TypeButton:nth-child(3).SelectedTypeButton {
  background: rgba(117, 143, 36, 0.3)
}

.TypeButton:nth-child(4).SelectedTypeButton {
  background: rgba(183, 100, 35, 0.3)
}

.TypeButton:nth-child(5).SelectedTypeButton {
  background: rgba(196, 165, 45, 0.5)
}


.SelectedTypeButton {
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.Separator {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 6px;
  margin-left: 8px;
  margin-right: 8px;
}