.ScannedDirectoryResourceView {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  > * {
    flex: 0 1;
  }

  > div:last-child {
    flex: 1 0;
  }
}

.DirectoryInfo {
  margin: 10px 0;
  vertical-align: middle;

  > div:last-child {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid white;
    margin: 0 10px;

    > span, > strong {
      margin-right: 5px;
    }
  }

  > div:first-child {
    vertical-align: middle;
    display: inline-block;
    margin: 0 10px;
    &:hover{
      cursor: pointer;
    }
  }
}

.PathPart {
  padding: 2px 3px;
  font-size: 12px;
}

.PathPartClickable{
  &:hover{
    cursor: pointer;
  }
}

.PathSeparator {
  padding: 2px 0;
  font-size: 14px;
  font-weight: bold;
}

.PathPartLast {
  text-decoration: underline;
  color: #71a114;
  font-weight: bold;
}

.ResourceDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 2px black solid;

  > div {
    width: 100%;
    height: 100%;
  }
}


.TableCell {
  font-size: 11px;
  padding: 0px 5px !important;
  vertical-align: middle;
  line-height: 25px;
}

.IconCell {
}

.IconHeaderCell {
}

.HeaderCell {
}

.SizeStrong {
  span:first-child {
    margin-right: 3px;
  }

  span:nth-child(2) {
    color: #a4c981;
  }
}

.TagIcons, .Actions {
  > span {
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover img {
    filter: brightness(1);
  }
}

.TagIcons img, .Actions img {
  height: 20px;
  vertical-align: middle;
  opacity: 0.5;
  filter: brightness(0.5);

  &:hover {
    opacity: 1;
  }
}

.TagIcons {

}


.Actions {

}