.FileResourceEntry {
  background: rgba(0, 0, 0, 0);
  width: 100%;

  &:hover {
    transition: all 0.2s ease;
    background: rgba(0, 0, 0, 0.1);
  }
}


.Ext {
  font-size: 12px;
  text-align: center;
}

.TagIcons {
  text-align: left;

  img {
    height: 22px;
    width: 22px;
    vertical-align: middle;
  }
}