.LibraryHome {
  height: 100%;
  width: 100%;

  & > div {
    height: 100%;
    width: 100%
  }

}

.Toolbars {

}
.MainToolbar {
}
.MainZone {

}

.NavigationView {
  > div {
    height: 100%;
  }
}
.PlayerInfo {

  > div {
    height: 100%;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }
}
.Playlist {
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.ResourceInfo {
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.15);
}
