.LibraryMainToolbar {
  padding: 3px 5px 2px 5px;
  background: rgba(0, 0, 0, 0.1);
}

.TypeSelector {
  margin-left: 5px;
  margin-top: 2px;
  img {
    width: 20px;
    filter: invert(1);
  }
}

.SearchInputZone{
  > div{
    width: 300px  ;
  }
}