.LibraryResourceInfoZone {
  height: 100%;

  > div {
    height: 100%;
  }
}

.MenuZone {

  > div {
    width: 100%;
  }
}

.Menus {

}


.Menu {
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.5);

  span {
    font-weight: bold;
  }
}

.MenuSelected {
  background: transparent;
  color: unset;
}

.InfoSelectionZone {
  width: 100%;
  height: 100%;
}

.InfoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: small;

  > div {
    position: absolute;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px 5px;
  }

  > div > div {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.InfoTitle{
  position: absolute;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  max-width: 98%;
  left: 0;
  top: 0;
}

.InfoLeft{
  max-width: 47%;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
}

.InfoRight{
  max-width: 47%;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  text-align: right;
}

.InfoCover{
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  text-align: center;

  img{
    position: relative;
    object-fit: contain;
    width: 95%;
    height: 95%;
  }
}