.YoutubeTrackDownloadForm {
  //width: 85%;
}

.Form {
  padding: 5px 10px 0 10px;

  h2, h3 {
    margin-bottom: 5px;
  }
}

.Upper {
  display: flex;
  flex-direction: row;
}

.UpperLeft {
  flex: 0 1 50%;

  > div {
    margin-bottom: 10px;
  }
}

.UpperRight {
  flex: 0 1 50%;

  > div {
    margin-bottom: 10px;
  }
}

.Bottom {
  > div {
    margin-bottom: 10px;
  }
}

.TrackInfoZone {
  padding: 5px 30px;
}

.TrackInfoEntry {
  //border: black 1px solid;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 20px;

  img {
    width: 200px;
    text-align: center;
  }

  h1 {
    margin-bottom: 5px;
    a {
    color: #dadada;
      text-decoration: none;
    }
  }

  div {
    > span:first-child {
      color: #afafaf;
      font-size: 14px;
      font-weight: bold;

      &::after {
        margin-left: 4px;
        margin-right: 4px;
        content: ":";
      }
    }

    > span:nth-child(2) {

    }

    > span:nth-child(3) {
      margin-left: 4px;
      font-style: italic;
      & a {
        text-decoration: none;
        color: grey;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

.Buttons {

  > span {
    display: block;
    padding: 6px 0;
    opacity: 0.8;
    transition: opacity 0.1s ease;
    font-weight: bold;

    &:hover {
      transition: opacity 0.1s ease;
      opacity: 1;
      cursor: pointer;
    }
  }

  > span:first-child {
    background-color: #678a23;
    color: white;
    text-align: center;
  }
}

.URL {
  h2 > * {
    display: inline-block;
    vertical-align: middle;
  }

  img {
    width: 18px;
    margin-left: 10px;
  }
}

.Presets {
  white-space: initial;

  h3 {
    &:hover span:nth-child(2) {
      transition: all 0.1s ease;
      color: white;
    }
  }
}

.Formats {
  margin-top: 5px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow: auto;
  width: 100%;

  > div {
    column-count: 3;
  }

  label {
    &:hover span:nth-child(2) {
      transition: all 0.1s ease;
      color: white;
    }
  }

  .Expanded {

    & + div {
      height: inherit;
    }
  }
}

.ConvertToAudio {
  margin-top: 5px;
  vertical-align: middle;

  & > div {
    vertical-align: middle;
  }

  & > label {
    vertical-align: middle;
    margin-left: 5px;
  }
}

.AudioFormat {
  margin-top: 5px;

  label {
  }

  > div {
    //display: inline-block;
  }
}