.LibraryFooter {
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
}

.ResourceStatCartouche {

}

.ResourceStatCartoucheButton {
  display: inline-block;
  font-size: 14px;

  &::after {
    margin-left: 8px;
    margin-right: 8px;
    content: "|";
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
  }

  > label {
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);
  }

  > label::after {
    margin-left: 2px;
    content: ":";
    margin-right: 5px;
  }

  > span {
    font-size: smaller;
    font-weight: bold;
  }
}