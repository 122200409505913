.ExplorerBlock {
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
}

.ContentWrapper {
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  height: 100%;

  & > div:first-child {
    flex: 0 1;
  }

  & > div:last-child {
    flex: 1 0;
    overflow: hidden;
  }
}

.ContentWrapper h1 {
  padding: 20px 20px 10px 20px;
}

.ContentWrapper h3 {
  margin-bottom: 8px;
  padding: 5px 20px;

  > span {
    margin-left: 5px;
    margin-right: 5px;
  }

  > span:last-child {
    color: #be4042;

    > div {

      display: inline-block;
      width: 300px;
    }
  }
}