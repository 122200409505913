.YoutubeDLDownloads {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  h2 {
    flex: 0 1;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
  }

  & > div:last-child {
    flex: 1 0;
  }
}

.DownloadList {
  overflow: auto;

  > div:nth-child(2n){
    background-color: rgba(0,0,0,0.2);
  }
}