.YoutubeDLDownloadEntry {
  padding: 5px;
  border-left: 5px solid transparent;
  line-height: 11px;

  & > div:first-child {
    display: flex;
    flex-direction: row;

    & > div:first-child {
      flex: 0 1;
      padding: 0 5px 0 0;

      span{
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      img{
        filter: invert(1);
        display: inline-block;
        vertical-align: middle;
        width: 20px;
      }
    }

    & > div:nth-child(2) {
      flex: 1 0;
    }

    & > div:nth-child(3) {
      flex: 0 1;
    }

    & > div:last-child {
      flex: 0 1 250px;
    }

  }

  &:hover {
    border-color: #888888;
  }

}

.Info {
  display: flex;
  vertical-align: middle;

  & > div {
  }
}

.Title {
  flex: 1 0;
  font-size: 11px;
  margin-bottom: 4px;
  font-weight: bold;
}


.Tiret {
  margin: 0 5px 0 5px;
}

.DlInfo {
  flex: 0 1;
  text-align: right;
  white-space: nowrap;
  font-size: 10px;
  filter: brightness(0.7);

  & >span:nth-child(1){
    color: #a2be49;
  }
  strong{
    filter: brightness(1.2);
  }
}

.Buttons {
  flex: 0 1;
  vertical-align: middle;
  margin: 0 5px 0 10px;

  > span {
    vertical-align: middle;
    span {
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
      width: 16px;
      filter: invert(1);
    }
  }
}

.ProgressBar {
  margin-top: 1px;
  margin-right: 5px;
  opacity: 0.7;
}