.RootDirectoryResourceEntry {
  padding: 10px 20px;
}

.RootDirectoryResourceEntry > div {
  display: flex;
  flex-direction: row;
  padding: 10px;
  vertical-align: middle;
  transition: filter 150ms ease-in;
  filter: brightness(1);
  border-style: solid;
  border-width: 8px 13px 8px 13px;

  &:hover {
    cursor: pointer;
    filter: brightness(1.5);
    transition: filter 150ms ease-out;
  }

  & > div:first-child {
    flex: 0 1 ;
  }

  & > div:nth-child(2){
    flex: 1 1  auto;
    > * {
      margin: 0 10px;
    }
  }

  & > div:last-child {
    min-width: 25%;
    flex: 0 1 ;

    > div {
      margin-right: 10px;
    }
  }
}

.Icon {
  filter: invert(1);
}

.RootTitle{
  font-weight: bold;
  font-size: 16px;
}

.RootLabels{
  padding: 5px 0;
  font-size: 12px;

  > span:nth-child(2n +1) {
    font-weight: bold;
    margin-right: 5px;
    margin-left: 15px;

    &::after {
      content: ":";
    }
  }

  > span:first-child {
    margin-left: 0;
  }
}