$border-size: 2px;
$line-height: 14px;

.ProgressBar {
  width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
}

.Wrapper {
  position: relative;
  margin: auto;
  width: inherit;
}

.BarWrapper {
  position: relative;
  width: inherit;
  height: $line-height;
  margin: auto;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: $line-height;
    margin: auto;
    border-radius: 4px;
  }
}

.Background {
  background-image: repeating-linear-gradient(-45deg,
          rgba(0, 0, 0, 0.1) 4px 14px,
          rgba(0, 0, 0, 0.2) 14px 24px);
  width: 100%;
}

.ProgressDiv {
  background-image: repeating-linear-gradient(-45deg,
          #618d0e 0 10px,
          #5b7a1f 10px 20px);

  &.Animate{
    animation: infinite BackgroundAnimation 500ms ease;
  }
}

@keyframes BackgroundAnimation {
  0% {
    background-image: repeating-linear-gradient(-45deg,
            #618d0e 0 10px,
            #5b7a1f 10px 20px);
  }
  50% {
    background-image: repeating-linear-gradient(-45deg,
            #5b7a1f 0 10px,
            #618d0e 10px 20px);
  }
  100% {
    background-image: repeating-linear-gradient(-45deg,
            #618d0e 0 10px,
            #5b7a1f 10px 20px);
  }
}

.BarWrapper.Transparent {

  .Background {
    background-color: rgba(255, 255, 255, 0.1);
    background-image: initial;
    border-color: rgba(255, 255, 255, 0.1);
    border-style: solid;
    border-width: $border-size;
  }

  .ProgressDiv {
    background-image: repeating-linear-gradient(-45deg,
            rgb(255, 255, 255, 0.5) 0 10px,
            rgb(255, 255, 255, 0.1) 10px 20px);
    top: $border-size;
    left: $border-size;
    //border-radius: 0 4px 4px 0;
  }

  & + .Text {
    left: calc($border-size);
    top: calc($border-size);

    > span {
      padding: 1px 2px 2px 2px;
      border-radius: 2px;
      background-color: rgba(0,0,0,0.3);
    }
  }
}

.BarWrapper.ThresholdCross{
  .Background {
    border-color: #6d080a;
  }
  & + .Text  span {
      color: #6d080a;
    background-color: rgba(255,255,255,0.3);
    }
}

.BarWrapper.ThresholdWarningCross{

  .Background {
    border-color: #cb7a1d;
  }
  & + .Text  span {
    color: #cb7a1d;
    background-color: rgba(0,0,0,0.3);
  }
}

.Text {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 9px;
  width: inherit;
  line-height: $line-height;
  vertical-align: middle;

  > span {
    font-weight: bold;
    margin: 0 10px;
  }
}