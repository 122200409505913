.PresetSelector {

}


.Preset {
  display: inline-block;
  padding: 4px 8px;
  margin: 2px 4px;
  opacity: 0.7;
  font-size: 11px;
  background-color: green;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  > span, > img {
    vertical-align: middle;
  }

  img{
    width: 20px;
    margin-right: 5px;
  }

  &.Selected {
    opacity: 1;
    color: white;

    img {
      filter: invert(1);
    }
  }

  &.audio {
    //background-color: #678a23;
  }

  &.video {
    //background-color: #6d080a;
  }

  &.mixed {
    //background-color: #944a14;
  }
}
