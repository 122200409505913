.TableCell {
  font-size: 11px;
  padding: 4px 5px !important;
}

.HeaderCell {
  font-size: 11px;
  padding: 3px 5px !important;
}

.SizeStrong {
  span:first-child {
    margin-right: 3px;
  }

  span:nth-child(2) {
    color: #a4c981;
  }
}

.DefinitionStrong {
  strong {
    font-weight: bold;
  }

  span:last-child {
    color: #a4c981;
  }
}

.DurationStrong {
  span {
    color: #a4c981;
  }
}

.ContextMenu {
  font-size: 14px;
  border: 2px solid rgba(0,0,0,0.5);
  //background: rgb(34, 35, 35);
  background: rgba(0,0,0,0.3);
  backdrop-filter: blur(15px);
}

.ContextMenuSeparator{
  margin: 5px 0;
  > div {
    width: 100%;
    border-bottom: 2px solid rgba(0,0,0,0.5);
  }
}

.ContextMenuItem{
  margin: 3px 0;

  padding: 0 5px;
}

.RedItem{
  color: rgb(203, 122, 124);
  //font-style: italic;
}

.ContextItemWithSub{
  //font-weight: bold;
  font-style: italic;
}