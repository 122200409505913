.Page {
}

.Content {
  flex: 1 1;
  overflow: auto;
  overflow-x: clip;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.HomeMainFrame {
  position: relative;
  width: inherit;
  height: inherit;
}

.MainAppBlock {
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.Header {
  background-color: #171717;
  padding: 10px 20px;
  flex: 0 1 auto;
  display: flex;

  & > div:last-child {
    flex: 0 1 auto;
  }
}

.HeaderContent {
  flex: 1 1 auto;
  font-weight: bold;

  > span {
    color: #71a114;
  }
}

.MainPanel {
  flex: 1 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
}

$right-pane-small-width: 300px;
$right-pane-big-width: 400px;

.RightPane {
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.3);
  flex: 1 1;
  @media only screen and (max-width: 1600px) {
    width: $right-pane-small-width;
    max-width: $right-pane-small-width;
    min-width: $right-pane-small-width;
  }
  @media only screen and (min-width: 1600px) {
    width: $right-pane-big-width;
    max-width: $right-pane-big-width;
    min-width: $right-pane-big-width;
  }

  > div {
    display: flex;
    height: 100%
  }

}

.Page {
  flex: 1 1 auto;
  overflow: hidden;
}

.Footer {
  flex: 0 1;
}

.Menus {
  li {
    padding: 5px 10px;

    & img {
      transition: 0.1s ease all;
      transform: scale(0.9);
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    &:hover img {
      transition: 0.1s ease all;
      transform: scale(1.2);
    }

    &.CurrentMenu img {
      transition: 0.1s ease all;
      transform: scale(1.2) !important;
    }
  }

  li a {
    display: flex;

    > div:first-child {
      flex: 0 1;
    }

    > div:last-child {
      flex: 1 0;
      padding: 10px 10px;
    }

  }
}

.Panel {
  background: #222526;
  flex: 0 1 auto;
}

.Panel li {
  text-align: center;
  transition: 0.1s ease all;
  border-right: transparent 10px solid;
}

.Panel li:hover {
  transition: 0.1s ease all;
  filter: brightness(1.2);
  cursor: pointer;
}

.Panel li a {
  transition: 0.1s ease all;
  text-decoration: none;
  white-space: nowrap;
  color: #E8E6E3;
}

.Panel li:hover a {
  transition: 0.1s ease all;
  text-decoration: none;
}

.Panel li a span {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.Panel li a img {
  vertical-align: middle;
}

.Panel li:first-child {
  background: rgb(36, 111, 143);
}

.Panel li:nth-child(2) {
  background: rgb(190, 47, 43);
}

.Panel li:nth-child(3) {
  background: rgb(117, 143, 36);
}

.Panel li:nth-child(4) {
  background: rgb(183, 100, 35);
}

.Panel li:nth-child(5) {
  background: rgb(196, 165, 45);
}

.Panel li:nth-child(6) {
  background: #d6005c;
}

.CurrentMenu {
  border-color: rgba(255, 255, 255, 0.5) !important;;
}

.Wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Title {
  flex: 0 1;
  padding: 20px 20px 10px 20px;
}


.HomePageMenuEntry {
  font-size: 24px;
  margin: 20px 20px;
  text-align: center;
  transition: 0.1s ease all;
  display: inline-block;
  border-radius: 20px;
  border: rgba(0, 0, 0, 0.5) 7px solid;

  a {
    padding: 20px 30px;
  }

  img {
    transition: 0.1s ease all;
    width: 50px;
    height: 50px;
    vertical-align: middle;
  }

  &:hover img {
    transition: 0.1s ease all;
  }

  &.CurrentMenu img {
    transition: 0.1s ease all;
  }

  &:hover {
    transition: 0.1s ease all;
    filter: brightness(1.2);
    cursor: pointer;
  }
}


.HomeMenuIcon {
  flex: 0 1;
}

.HomeMenuName {
  flex: 1 0;
  padding: 10px 10px;
}

.PopinRoot {
  //background-color: transparent !important;
  background-color: transparent !important;
  border: 15px rgba(0, 0, 0, 0.5) solid;
  border-radius: 0 !important;
  //background-image: url("https://i.etsystatic.com/20630033/r/il/9aa38f/3350237775/il_570xN.3350237775_l8r0.jpg");
  //background-image: url("https://blog.miimosa.com/wp-content/uploads/2020/02/Miimosa_Financement_Projet_Agroforesterie_permaculture_.png");

  > div:first-child {
    background: #363B3D;
    //backdrop-filter: blur(5px);
    position: relative;
  }
}


.PopinTitle, .PopinContent {
  position: relative;
}

.PopinHeader {
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.PopinTitle {
  font-size: 16px;

  img {
    display: none;
  }
}

.PopinContent {
  padding: 5px 15px 0 15px;

  h1 {
    font-size: 12px;
  }
}

.PopinContainer {
  > div:last-child {
    width: 800px !important;
  }
}


.PopinActionRow {
  > div {
    margin: 0
  }
}